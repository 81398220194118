/*
 * File: BlogPost.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 3, 2023 at 10:52 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { scrollPageTo } from '../../../utilities/position';
import { buildDynamicMeta } from '../../../components/Meta';
import { createStateLocale } from '../../../utilities/locale';
import { dateObj, formatMinuteLength } from '../../../../utilities/dateTime';

// Services
import { getBlogPost } from '../../../services/blog';

// Components
import {
	Meta, AppNavigation, Typography, BlogSection, Badge, Button, Spinner, ErrorComponent, SchemaScript
} from '../../../components';

// Constants
import { SHARE_OPTIONS } from '../../../../Constants';

// Styles
import * as S from './BlogPost.styles';


/**
 * Component
 */

const BlogPost = ({ meta, locale, data }) => {

	// Get component parameters
	const { slug } = useParams();

	// Create reference for components
	const isMounted = useRef(true);
	const shouldReload = useRef(false);
	const enableDynamicLoad = useRef(false);

	// Create state handlers
	const [pageStatus, setPageStatus] = useState(!data?.story ? 'idle' : 'success');
	const [story, setStory] = useState(data?.story);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Handle sharing action
	const shareAction = (action) => {

		// Create share content
		const shareURL = `${process.env.APP_URL}/story/${story?.slug}`;
		const shareTitle = story?.title;
		const shareImage = story?.featureImage?.url;

		// Handle action
		switch (action) {
			case SHARE_OPTIONS.FACEBOOK: {

				// Share to Facebook
				const url = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(shareURL)}`;
				window.open(url, '_blank');

				// Break
				break;
			}
			case SHARE_OPTIONS.TWITTER: {

				// Create content
				const content = `Check out ${shareTitle} 👀`;

				// Share to Twitter
				const url = `https://twitter.com/share?url=${encodeURIComponent(shareURL)}&text=${encodeURIComponent(content)}`;
				window.open(url, '_blank');

				// Break
				break;
			}
			case SHARE_OPTIONS.REDDIT: {

				// Create content
				const content = `Check out ${shareTitle}`;

				// Share to Reddit
				const url = `https://reddit.com/submit?url=${encodeURIComponent(shareURL)}&title=${encodeURIComponent(content)}`;
				window.open(url, '_blank');

				// Break
				break;
			}
			case SHARE_OPTIONS.PINTEREST: {

				// Share to Pinterest
				const url = `https://pinterest.com/pin/create/bookmarklet/?media=${encodeURIComponent(shareImage)}&url=${encodeURIComponent(shareURL)}&is_video=true&description=${encodeURIComponent(shareTitle)}`;
				window.open(url, '_blank');

				// Break
				break;
			}
			case SHARE_OPTIONS.LINKEDIN: {

				// Share to LinkedIn
				const url = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(shareURL)}}`;
				window.open(url, '_blank');

				// Break
				break;
			}
			case SHARE_OPTIONS.EMAIL: {

				// Create content
				const content = `Check out ${shareTitle}`;

				// Share to email
				const url = `mailto:?Subject=${encodeURIComponent(content)}&Body=${encodeURIComponent(shareURL)}`;
				window.open(url, '_blank');

				// Break
				break;
			}
			default:
				break;
		}
	};

	// Handle fetch data for page
	const fetchDataForPage = async () => {

		// Update page status
		setPageStatus('loading');
		try {

			// Fetch current blog topic if necessary
			if (!story || shouldReload.current === true) {

				// Fetch blog story
				const { data: datum } = await getBlogPost({ slug });

				// Set new data state
				if (isMounted.current) {

					// Update component data
					setStory(datum.story);
				}
			}

			// Update state
			shouldReload.current = false;

			// Update page status
			if (isMounted.current) {
				setPageStatus('success');
			}
		} catch (error) {

			// Ensure component is mounted
			if (isMounted.current) {

				// Update page status
				setPageStatus('error');
			}
		}
	};

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (pageStatus === 'idle') {

			// Fetch data state for page
			fetchDataForPage();
		}
	}, [pageStatus]);

	// Handle actions on route change (dynamic page)
	useEffect(() => {

		// Set page status if necessary
		if (enableDynamicLoad.current === true && pageStatus !== 'idle') {

			// Update state
			shouldReload.current = true;

			// Reset page status
			setPageStatus('idle');
		}

		// Scroll page to top
		scrollPageTo(0, 0);

	}, [slug]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Enable dynamic load
		enableDynamicLoad.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Handle component render
	const renderComponent = () => {
		if (pageStatus === 'idle' || pageStatus === 'loading') {
			return <Spinner showMeta meta={meta} />;
		} if (pageStatus === 'error') {
			return <ErrorComponent locale={stateLocale} />;
		}
		return (
			<>
				{/* Content Padding */}
				<S.ContentPadding>

					{/* Topic */}
					<S.TopicContainer>
						{story?.tags.map((tag) => (
							<S.LocaleLink to={`/blog/topic/${tag.slug}`} key={tag.slug}>
								<Badge size="large"><Typography weight="semibold">{tag.title}</Typography></Badge>
							</S.LocaleLink>
						))}
					</S.TopicContainer>

					{/* Title */}
					<Typography tag="h1" weight="extrabold">{story?.title}</Typography>

					{/* Detail Container */}
					<S.DetailContainer>

						{/* Author */}
						<S.Detail>
							<Typography variation="1" weight="semibold">
								By
								{' '}
								{story?.author?.name}
							</Typography>
						</S.Detail>

						{/* Publish Date */}
						<S.Detail>
							<FontAwesomeIcon icon={['fasl', 'calendar']} />
							<Typography variation="1">{dateObj(story.publishedTime).format('MMMM D, YYYY')}</Typography>
						</S.Detail>

						{/* Read Time */}
						<S.Detail>
							<FontAwesomeIcon icon={['fasl', 'clock']} />
							<Typography variation="1">{formatMinuteLength(story.timeToRead)}</Typography>
						</S.Detail>

					</S.DetailContainer>
				</S.ContentPadding>

				{/* Image */}
				<S.BlogImage src={story?.featureImage?.url} alt={story?.featureImage?.alt || `${story?.title} on the 700 Rivers Blog`} />

				{/* Content Padding */}
				<S.ContentPadding>

					{/* Content */}
					<S.ContentContainer className="paragraphContent" dangerouslySetInnerHTML={{ __html: story?.content }} />

					{/* Share Container */}
					<S.ShareContainer>

						{/* Title */}
						<Typography tag="h4" weight="bold">Share this story</Typography>

						{/* Share Widgets */}
						<S.ShareWidgetContainer>

							{/* Facebook */}
							<Button variant="solid" className="facebook" onClick={() => { shareAction(SHARE_OPTIONS.FACEBOOK); }}>
								<FontAwesomeIcon icon={['fab', 'facebook']} />
								<Typography weight="semibold">Facebook</Typography>
							</Button>

							{/* Twitter */}
							<Button variant="solid" className="twitter" onClick={() => { shareAction(SHARE_OPTIONS.TWITTER); }}>
								<FontAwesomeIcon icon={['fab', 'twitter']} />
								<Typography weight="semibold">Twitter</Typography>
							</Button>

							{/* Reddit */}
							<Button variant="solid" className="reddit" onClick={() => { shareAction(SHARE_OPTIONS.REDDIT); }}>
								<FontAwesomeIcon icon={['fab', 'reddit-alien']} />
								<Typography weight="semibold">Reddit</Typography>
							</Button>

							{/* Pinterest */}
							<Button variant="solid" className="pinterest" onClick={() => { shareAction(SHARE_OPTIONS.PINTEREST); }}>
								<FontAwesomeIcon icon={['fab', 'pinterest-p']} />
								<Typography weight="semibold">Pinterest</Typography>
							</Button>

							{/* LinkedIn */}
							<Button variant="solid" className="linkedin" onClick={() => { shareAction(SHARE_OPTIONS.LINKEDIN); }}>
								<FontAwesomeIcon icon={['fab', 'linkedin-in']} />
								<Typography weight="semibold">LinkedIn</Typography>
							</Button>

							{/* Email */}
							<Button variant="solid" className="email" onClick={() => { shareAction(SHARE_OPTIONS.EMAIL); }}>
								<FontAwesomeIcon icon={['fasl', 'envelope']} />
								<Typography weight="semibold">Email</Typography>
							</Button>

						</S.ShareWidgetContainer>
					</S.ShareContainer>

				</S.ContentPadding>

				{/* Related stories */}
				<S.SectionContainer>
					<Typography tag="h3" weight="bold" className="title">More stories</Typography>
					<BlogSection
						key="blogSection"
						perRow={4}
						limit={4}
						fetchParams={{ pageSize: 4, excludedStories: [story?.id].filter(Boolean), relatedId: story?.id }}
						locale={stateLocale}
					/>
				</S.SectionContainer>
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} data={{ story: story || { title: 'Loading...' } }} />

			{/* Schema.org BreadcrumbList */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': 'BreadcrumbList',
				'@id': `${process.env.APP_URL}/story/${slug}/#BreadcrumbList`,
				itemListElement: [
					{
						'@type': 'ListItem',
						position: 1,
						item: {
							'@id': `${process.env.APP_URL}/blog/#ListItem`,
							name: 'Blog',
							url: `${process.env.APP_URL}${stateLocale.localePath}/blog`
						}
					},
					{
						'@type': 'ListItem',
						position: 2,
						item: {
							'@id': `${process.env.APP_URL}/story/${slug}/#ListItem`,
							name: buildDynamicMeta(meta.title, { story }),
							url: `${process.env.APP_URL}${stateLocale.localePath}/story/${slug}`
						}
					}
				]
			}}
			/>

			{/* Schema.org WebPage */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': 'WebPage',
				'@id': `${process.env.APP_URL}/story/${slug}/#WebPage`,
				name: buildDynamicMeta(meta.title, { story }),
				description: buildDynamicMeta(meta.description, { story }),
				url: `${process.env.APP_URL}${stateLocale.localePath}/story/${slug}`,
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${process.env.APP_URL}/#WebSite`
				},
				breadcrumb: {
					'@id': `${process.env.APP_URL}/story/${slug}/#BreadcrumbList`
				},
				potentialAction: [
					{
						'@type': 'ReadAction',
						target: `${process.env.APP_URL}${stateLocale.localePath}/story/${slug}`
					}
				]
			}}
			/>

			{/* Schema.org Article */}
			<SchemaScript schema={{
				'@context': 'http://schema.org',
				'@type': 'Article',
				'@id': `${process.env.APP_URL}/story/${slug}/#Article`,
				headline: story?.title,
				image: story?.featureImage?.url,
				inLanguage: stateLocale.localeBaseId,
				isPartOf: {
					'@id': `${process.env.APP_URL}/story/${slug}/#WebPage`
				},
				author: {
					'@type': 'Person',
					name: story?.author?.name,
				},
				publisher: {
					'@id': `${process.env.APP_URL}/#Organization`
				}
			}}
			/>

			{/* Component Content */}
			<AppNavigation>
				<S.Wrapper>{renderComponent()}</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

BlogPost.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
BlogPost.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default BlogPost;
